@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-slide * {
  border: none !important;
  border-style: none !important;
}

@font-face {
  font-family: 'Metric-Light';
  src: url('./fonts/Metric-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Metric-Black';
  src: url('./fonts/Metric-Black.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Metric-Regular';
  src: url('./fonts/Metric-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Metric-SemiBold';
  src: url('./fonts/Metric-SemiBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

.custom-font {
  font-family: 'Metric-Regular';
}

.custom-font-black {
  font-family: 'Metric-Black';
}

.custom-font-light {
  font-family: 'Metric-Light';
}

.custom-font-SemiBold {
  font-family: 'Metric-SemiBold';
}

html {
  /* scroll-behavior: smooth; */
  /* Sayfa kaydırma davranışını kontrol eder */
  overscroll-behavior-y: none;
  /* Safari'de adres çubuğunun otomatik olarak küçülmesini engeller */
}

/* Projelerin üzerine gelinmediğinde siyah-beyaz yap */
.project-item img {
  filter: grayscale(100%);
  transition: filter 0.2s ease-in-out;
  backface-visibility: hidden;
}

.project-item:hover img {
  filter: none;
}


/* Projelerin üzerine gelindiğinde renklendir */
@media (min-width: 768px) {
  .project-item:hover img {
    filter: grayscale(0%);
  }
}

@keyframes lineAnimation {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(300%);
  }
}

.animate-line {
  animation: lineAnimation 4s linear infinite;
  /* İsterseniz süre ve animasyon türünü ayarlayabilirsiniz */
}

/* Webkit tarayıcıları için */
::-webkit-scrollbar {
  width: 10px;
  display: none;
  /* Kaydırma çubuğu genişliği */
}

::-webkit-scrollbar-thumb {
  background-color: black;
  display: none;
  /* Kaydırma çubuğu rengi */
}

/* Diğer tarayıcılar için */
::-webkit-scrollbar {
  width: 10px;
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: black;
  display: none;
}

/* Tüm tarayıcılar için */
body {
  scrollbar-width: thin;
  /* Firefox için gereklidir */
  scrollbar-color: black transparent;
  /* Diğer tarayıcılar için gereklidir */
}

.my-custom-animation {
  animation-duration: 2000ms;
}

.loader {
  width: 20px;
  height: 4px;
  display: block;
  margin: auto;
  position: relative;
  border-radius: 4px;
  color: #FFF;
  background: currentColor;
  box-sizing: border-box;
  animation: animloader 0.6s 0.3s ease infinite alternate;
}

.loader::after,
.loader::before {
  content: '';
  box-sizing: border-box;
  width: 20px;
  height: 4px;
  background: currentColor;
  position: absolute;
  border-radius: 4px;
  top: 0;
  right: 110%;
  animation: animloader 0.6s ease infinite alternate;
}

.loader::after {
  left: 110%;
  right: auto;
  animation-delay: 0.6s;
}

@keyframes animloader {
  0% {
    width: 20px;
  }

  100% {
    width: 48px;
  }
}

.text-animate {
  color: hsl(0, 0%, 28%);
  font-size: 50px !important;
  letter-spacing: 7px !important;
  cursor: pointer;
  text-transform: uppercase;
}

.text-animate {
  padding-top: 64px;
  padding-bottom: 64px;
  background: linear-gradient(to right, hsl(0, 0%, 30%) 0, hsl(0, 0%, 100%) 10%, hsl(0, 0%, 30%) 20%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
}

@keyframes shine {
  0% {
    background-position: 0;
  }

  60% {
    background-position: 600px;
  }

  100% {
    background-position: 600px;
  }
}